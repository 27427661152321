import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import chris from "../../content/assets/chris_avatar.svg"
import clarissa from "../../content/assets/clarissa_avatar.svg"
import kris from "../../content/assets/kris_avatar.svg"

function CPara(props) {
  return(
    <p className="text-gray-800 py-2">
      {props.children}
    </p>
  )
}

function CUListI(props){
  return(<li className="text-gray-800">{props.children}</li>)
}

function COListI(props){
  return(
    <li className="text-gray-800 pl-4">{props.children}</li>
  )
}

function COList(props){
  return(
    <ol className="ml-8 pl-4 list-decimal ml-4 -mt-2 pb-2">{props.children}</ol>
  )
}

function CUList(props) {
  return(
    <ul className="list-disc ml-4 -mt-2 pb-2">{props.children}</ul>
  )
}

function CHeadingTwo(props) {
  return(
    <h2 className="my-4 text-2xl font-semibold leading-tight">{props.children}</h2>
  )
}

function MissionStatement(props){
  return(
    <div className="w-full px-4 md:px-8 mt-2 my-4">
      <blockquote className="relative p-4 mb-2 text-xl italic border-l-4 quote">
        <p className="mb-2">
        To further the recognition, professionalism, and excellence of content creators.
        </p>
      </blockquote>
      <CPara>
        We further <strong><em>Professionalism</em></strong> by requiring our members align themselves with our <Link className="border-b border-blue-200" to="/code-of-conduct">Code of Conduct</Link>.
        The Code of Conduct provides guidance on how our raise the level of confidence the public has in our profession.
      </CPara>
      <CPara>
        We further the <strong><em>Recognition</em></strong> of our members and profession by our efforts to advertise and advocate for our
        members and the professional standard they hold themselves to.
      </CPara>
      <CPara>
        We further the <strong><em>Excellence</em></strong> of our members by our commitment to training, learning, and personal development.
        We assist members to learn and develop themselves as professionals, while also seeking a commitment from members that
        they will seek their own personal development.
      </CPara>
    </div>
  )
}

function OurMembers(props){
  return (
    <div className="w-full px-4 md:px-8 mt-2 my-4">
      <CPara>
        Our members draw from their expereince, interest, and drive for
        content creation; they harness this in their work to drive the
        <em>professionalism</em> that the WCCA reresents.
      </CPara>
      <CPara>
        All our members agree to uphold the WCCA <Link className="border-b border-blue-200" to="/code-of-conduct">Code of Conduct</Link>.
        Our Code of Conduct provides guidance on how our members demonstrate their professionalism, and raise the level
        of confidence the public has in our profession.
      </CPara>
    </div>
  )
}

function OurHistory(props){
  return (
    <div className="w-full px-4 md:px-8 mt-2 my-4">
      <CPara>
        The WCCA was born out of a group of content creators and employers looking for a
        way to advertise and verify the quality of their work with accountability; to make it
        easier to hire and be hired using freelancing websites in particular.
      </CPara>
    </div>
  )
}

function CHeadingThree(props) {
  return(
    <h3 className="my-4 text-xl font-semibold leading-tight">{props.children}</h3>
  )
}

function CommitteeCard(props){

  return (
    <div className="sm:w-4/12 p-4">
      <div className="bg-white px-4 py-4 rounded-lg shadow-lg text-center">
        <div class="mb-3">
          <div className="w-full">
            <img 
              className="w-20 mx-auto rounded-full"
              src={props.avatar}
              alt={props.name}
              alt="Logo" />
          </div>
        </div>
        <h2 className="text-xl font-medium text-gray-900 mb-1">{props.name}</h2>
        <div className="text-gray-800 block">
          <p className="mb-2">
            {props.description}
          </p> 
        </div> 
      </div>
    </div>
  )

}

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="About Us"
        />
        <div className="container mx-auto max-w-5xl">
          <h1 className="my-4 text-2xl md:text-3xl font-extrabold leading-tight">
            About Us
          </h1>
          <p className="leading-normal text-gray-800 text-base md:text-xl mb-8">
            Find out more below about our mission, our membership committee, our members, and our history
          </p>
        </div>

        <section className="bg-gray-100 border-b py-4">
          <div className="container mx-auto max-w-5xl flex flex-wrap pt-2 pb-6">
            <div className="w-full md:w-1/3 p-4 flex flex-col flex-grow flex-shrink">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="flex flex-wrap">
                  <h2 className="w-full font-bold text-2xl text-gray-800 px-6 mt-4">
                    Our Mission
                  </h2>
                  <MissionStatement/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-8">
          <div className="container mx-auto max-w-5xl flex flex-wrap pt-4 pb-12">
            <div className="w-full md:w-1/3 p-4 flex flex-col flex-grow flex-shrink">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full font-bold text-2xl text-gray-800 px-6 mt-2">
                    Our Membership Committee
                  </div>
                  <div className="w-full mx-auto flex flex-wrap justify-center mb-4 px-6"> 
                    <CommitteeCard
                      name="Kris"
                      description="Kris is an entrepreneur with a strong history of work in the finance industry. Using this, he specialises in creating financial YMYL content."
                      avatar={kris}
                    />
                    <CommitteeCard
                      name="Clarissa"
                      description="Clarissa has worked extensively in marketing and content creation in the medical field. Clarissa specialises in YMYL (medical) content"
                      avatar={clarissa}
                    />
                    <CommitteeCard
                      name="Chris"
                      description="Chris has a blogging background, having contributed to blogs in sports, travel, software, and many other diverse niches."
                      avatar={chris}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-100 py-8">
          <div className="container mx-auto max-w-5xl flex flex-wrap pt-4 pb-12">
            <div className="w-full md:w-1/3 p-4 flex flex-col flex-grow flex-shrink">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full font-bold text-2xl text-gray-800 px-6 mt-2">
                    Our Members
                  </div>
                  <OurMembers/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-8">
          <div className="container mx-auto max-w-5xl flex flex-wrap pt-4 pb-12">
            <div className="w-full md:w-1/3 p-4 flex flex-col flex-grow flex-shrink">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full font-bold text-2xl text-gray-800 px-6 mt-2">
                    Our History
                  </div>
                  <OurHistory/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
    /*
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <div className="w-full">
          <div className="text-center px-6 py-12 mb-6 bg-gray-100 border-b">
            <h1 className=" text-xl md:text-4xl pb-4">About Landing Last Minute</h1>
          </div>
        </div>
        <div className="simple-post">
          <div className="simple-post container max-w-5xl mx-auto py-8">
              <AboutShort />
              <h2>Contact</h2>
              <p>Want to get in touch? Send an email to{' '} 
                <a href="mailto:contact@landinglastminute.com">contact@landinglastminute.com</a>.</p>
              <Bio />
          </div>
        </div>
        
      </Layout>
    )
    */
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`